import axios from 'axios'

import qs from 'qs'

import {
    message
} from 'ant-design-vue'

import router from '../router/router.js';

axios.defaults.timeout = 200000;

axios.defaults.baseURL = process.env.VUE_APP_BASE_API + "/";

//POST传参序列化
axios.interceptors.request.use((config) => {
    // config.data = config.data || {};
    // config.data.auth_token = localStorage.getItem('account-token');
    // config.data = qs.stringify(config.data);
    // config.headers = {
    //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    // };
    if (config.method === 'post') {
        if (config.headers['Content-Type'] !== 'multipart/form-data;charset=UTF-8') {
            config.data = config.data || {};
            config.data.auth_token = localStorage.getItem('account-token');
            config.data = qs.stringify(config.data);
        } else { //上传文件逻辑
            config.data = config.data;
            config.data.append('type', 'beautiful'); //来源哪里的上传
            config.data.append('auth_token', localStorage.getItem('account-token'));
        }
    }
    return config;

}, (error) => {

    message.error('网络请求超时');
    return Promise.reject(error);

});

//返回状态判断
axios.interceptors.response.use((res) => {
    if (res.status == 200) {
        return res;
    } else {
        return Promise.reject(res);
    }
}, (error) => {
    //console.log(error);
    return Promise.reject(error);
});

export function upload(file) {
    var datas = new FormData();
    var configs = {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
        }
    };
    console.log('flie', file);
    datas.append('file', file);
    // datas.type = 'beautiful';
    return axios.post('platform/upload', datas, configs)
        .then(response => {
            switch (response.data.code) {
                case 200:
                    return response.data.data;
                default:
                    return Promise.reject(response.data.msg || 'error');
            }
        })
        .catch((msg) => {
            return Promise.reject(msg || 'error')
        });
}

// export function api(url, params) {

//     return new Promise((resolve, reject) => {

// 		axios.post(url, params)

// 		    .then(response => {

// 		        switch (response.data.code) {

// 		            case 100:

// 		                message.error(response.data.msg);

// 						localStorage.removeItem('account-token');

// 						router.push('/');

// 		                return reject(response.data.msg || 'error');

// 		            case 200:

// 		                return response.data.data;

// 		            case 400:  //400 为提示类型错误

// 						message.error(response.data.msg);

// 		                return reject(response.data.msg || 'error');

// 					case 403:  //403 没有权限

// 						message.error('您没有权限');

// 						return reject(response.data.msg || 'error');

// 		            case 500:

// 						return reject('网络繁忙');

// 		                break;

// 		            default:

// 		                return reject(response.data.msg || 'error');

// 		        }

// 		    })

// 		    .catch((msg) => {

// 		        return reject(msg || 'error')

// 		    })

// 			;

// 	});

// }


export function api(url, params) {
    return axios.post(url, params)
        .then(response => {
            switch (response.data.code) {
                case 100:
                    message.error(response.data.msg);
                    localStorage.removeItem('account-token');
                    router.push('/');
                    return Promise.reject(response.data.msg || 'error');
                case 200:
                    return response.data.data;
                case 400: //400 为提示类型错误
                    message.error(response.data.msg);
                    return Promise.reject(response.data.msg || 'error');
                case 500:
                    return Promise.reject('网络繁忙');
                    break;
                default:
                    return Promise.reject(response.data.msg || 'error');
            }
        })
        .catch((msg) => {
            return Promise.reject(msg || 'error')
        });
}